import './index.scss'
import '../../assets/scripts/countNumber'
// import '../../assets/scripts/myNiceScroll'
// import {resizeScroll} from '../../assets/scripts/myNiceScroll'
import Swiper from 'swiper/dist/js/swiper.min.js'

//数字滚动效果
function numScroll () {
  let num1 = $("#scrollNum1").html()
  // let num2 = $("#scrollNum2").html()
  let num3 = $("#scrollNum3").html()
  let num4 = $("#scrollNum4").html()
  $("#scrollNum1").rollNum({
      deVal: num1
  });
  // $("#scrollNum2").rollNum({
  //     deVal: num2
  // });
  $("#scrollNum3").rollNum({
      deVal: num3
  });
  $("#scrollNum4").rollNum({
      deVal: num4
  });
}

//banner轮播
var indexBannerswiper = new Swiper(".index-banner .mySwiper", {
  speed: 1000,
  loop: true,
  autoplay: {
    delay: 6000,
    stopOnLastSlide: false,
    disableOnInteraction: false,
    waitForTransition: false,
  },
  effect : 'fade',
  pagination: {
    el: '.index-banner .swiper-pagination',
    clickable: true,
  },
  on:{
    init:function(){
      setTimeout(function(){
        let pageLenght = $('.index-banner .swiper-pagination .swiper-pagination-bullet').length
        let total = pageLenght > 9 ? pageLenght : '0'+ pageLenght
        $('.index-banner .banner-contral .left-btn').show()
        $('.index-banner .swiper-page .total').html(total)
        $('.index-banner .banner-contral .left-btn a').eq(this.realIndex).show().siblings().hide()
      },200)
      
    },
    transitionStart: function(){
      let activeNum = this.realIndex+1
      activeNum = activeNum > 9 ? activeNum : '0'+ activeNum
      $('.index-banner .swiper-page .active-num').html(activeNum)
      $('.index-banner .banner-contral .left-btn a').eq(this.realIndex).show().siblings().hide()
    },
  }
});

//品牌轮播
var brandSwiper = new Swiper('.section1 .cont2 .swiper',{
  slidesPerView: 5,
  slidesPerGroup: 5,
  pagination: {
    el: ".section1 .cont2 .swiper-pagination",
    clickable: true
  },
  breakpoints: { 
    1024: {
      slidesPerView: 5,
      slidesPerGroup: 5,
    },
    768: {
      slidesPerView: 4,
      slidesPerGroup: 4,
    },
    640: {
      slidesPerView: 2,
      slidesPerGroup: 2,
    },
  }
})

//服务轮播
var serviceswiper1 = new Swiper(".section4 .right .img-swiper .mySwiper", {
  speed: 500,
  on: {
    init: function(swiper){
      $(".section4 .left .tab .item").eq(this.activeIndex).addClass('active')
    },
    slideChangeTransitionStart: function(){
      $(".section4 .left .tab .item").eq(this.activeIndex).addClass('active').siblings().removeClass('active')
      serviceswiper2.slideTo(this.activeIndex)
    },
  },
});
var serviceswiper2 = new Swiper(".section4 .right .text-swiper .mySwiper", {
  allowTouchMove: false,
  effect : 'fade',
  speed: 500,
  fadeEffect: {
    crossFade: true,
  }
});
$(".section4 .left .tab .item").hover(function(){
  let index = $(this).index()
  serviceswiper1.slideTo(index)
})

//新闻轮播
var newswiper = new Swiper(".section5 .mySwiper", {
  slidesPerView: 3,
  spaceBetween: 45,
  slidesPerGroup: 3,
  speed: 500,
  navigation: {
    nextEl: ".section5 .swiper-button-next",
    prevEl: ".section5 .swiper-button-prev",
  },
  breakpoints: { 
    1280: {
      slidesPerView: 3,
      spaceBetween: 45,
      slidesPerGroup: 3,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
      slidesPerGroup: 3,
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 30,
      slidesPerGroup: 2,
    },
    640:{
      slidesPerView: 1,
      spaceBetween: 20,
      slidesPerGroup: 1,
    }
  }
});

//页面滚动监听
var beforeScroH = $(document).scrollTop();
$(document).scroll(function () {
  let scroH = $(document).scrollTop(); //滚动高度
  let viewH = $(window).height(); //可见高度
  let contentH = $(document).height(); //内容高度
  let numTop = $('.section1 .num-cont').offset().top

  let mapTop = $('.section2 .map-cont').offset().top - viewH
  let proTop = $('.section3').offset().top
  let serviceTop = $('.section4').offset().top

  if (scroH > numTop - viewH) {
    numScroll()
  }

  //全屏区域隐藏菜单
  // let headertranTop = $('.section2').offset().top - 100
  // let headertranBtm = $('.section5').offset().top - 200
  // if($(window).width() > 1024 && scroH > headertranTop && scroH < headertranBtm){
  //   $('.header').addClass('trans')
  // }else{
  //   $('.header').removeClass('trans')
  // }

  // 地图
  let scaleMap = 0
  let scaleBg = 0
  if(scroH < mapTop){
    scaleMap = 0
    scaleBg = 0
  }else if(scroH > mapTop && scroH < proTop - viewH){
    scaleMap = (scroH - mapTop) * 0.002 >= 1 ? 1 : (scroH - mapTop) * 0.002
    scaleBg = (scroH - mapTop) * 0.002 >= 0.92 ? 0.92 : (scroH - mapTop) * 0.002
  }else if(scroH > proTop - viewH){
    scaleMap = (scroH - mapTop) * 0.002 >= 1 ? 1 : (scroH - mapTop) * 0.002
    scaleBg = (scroH - proTop + viewH) * 0.0003 + 0.92 >= 1.1 ? 1.1 : (scroH - proTop + viewH) * 0.0003 + 0.92
  }
  // $('.section2 .bgcolor').css('transform','scale('+ scaleBg +')');
  if($(window).width() > 767){
    $('.section2 .map-cont .map').css('transform','scale('+ scaleMap +')');
    if(scaleMap >= 0.9 && scroH < proTop - viewH/3){
      $('.section2').addClass('show')
    }else{
      $('.section2').removeClass('show')
    }
  }
  
  // if(scroH > proTop - 9*viewH/10){
  //   $('.section2').addClass('trans-color')
  // }else{
  //   $('.section2').removeClass('trans-color')
  // }
  
  //产品
  if($(window).width() > 1024){
    // 设置section3的内容浮动
    if(scroH > proTop - $('.header').height() && scroH < serviceTop - viewH){
      $('.section3').addClass('fixed').removeClass('abs')
      // 设置section3的内容横向滚动
      $('.section3 .cont ul').css('transform', 'translateX(-' + (scroH - proTop) + 'px)')
    }else if(scroH >= serviceTop - viewH){
      $('.section3').removeClass('fixed').addClass('abs')
    }else{
      $('.section3').removeClass('fixed').removeClass('abs')
    }
    // 进度条
    let progress = ((scroH - proTop)/($('.section3 .cont ul').outerWidth() - $(window).width()))*100
    $('.section3 .section3-title .progress div').css('width', progress + '%')
  }

  //服务
  // let scaleserviceX
  // let scaleserviceY
  // if(scroH > serviceTop - viewH/2){
  //   scaleserviceX = (scroH - serviceTop + viewH/2) * 0.0003 >= 0.08 ? 0.08 : (scroH - serviceTop + viewH/2) * 0.0003
  //   scaleserviceY = (scroH - serviceTop + viewH/2) * 0.0005 >= 0.15 ? 0.15 : (scroH - serviceTop + viewH/2) * 0.0005
  // }else{
  //   scaleserviceX = 0
  //   scaleserviceY = 0
  // }
  // $('.section4 .bg-swiper .bg-mask .top,.section4 .bg-swiper .bg-mask .bottom').css('transform','scaleY('+ scaleserviceY +')');
  // $('.section4 .bg-swiper .bg-mask .left,.section4 .bg-swiper .bg-mask .right').css('transform','scaleX('+ scaleserviceX +')');

  // if(scroH > $('.section5').offset().top - viewH*3/4){
  //   let transNum = scroH - $('.section5').offset().top + viewH*3/4
  //   $('.section4 .bg-swiper .bg-mask .top').css('transform','scaleY('+ scaleserviceY +') translateY(-'+ transNum +'px)');
  //   $('.section4 .bg-swiper .bg-mask .bottom').css('transform','scaleY('+ scaleserviceY +') translateY('+ transNum +'px)');
  //   $('.section4 .bg-swiper .bg-mask .left').css('transform','scaleX('+ scaleserviceX +') translateX(-'+ transNum*2 +'px)');
  //   $('.section4 .bg-swiper .bg-mask .right').css('transform','scaleX('+ scaleserviceX +') translateX('+ transNum*2 +'px)');
  // }

 

  //判断页面是上滚还是下滚,自动吸附
  var solution1Top = $('.section1').offset().top;
  var solution2Top = $('.section2').offset().top;
  var solution3Top = $('.section3').offset().top;
  var solution4Top = $('.section4').offset().top;

  var delta = scroH - beforeScroH
  if (window.screen.width > 1024){
      if (scroH > solution1Top - viewH / 3 && scroH < solution1Top - viewH / 3 + 20) {
          if (delta > 0) {
              // console.log('下滚1')
              $('html ,body').animate({
                  scrollTop:  $('.section1').offset().top - $('.header').height()
              }, 300);
          }
          if (delta < 0) {
              return false;
          }
      } else if (scroH > solution2Top - viewH / 3 && scroH < solution2Top - viewH / 3 + 20) {
        if (delta > 0) {
            // console.log('下滚2')
            $('html ,body').animate({
                scrollTop: $('.section2').offset().top - $('.header').height()
            }, 300);
        }
        if (delta < 0) {
            return false;
        }
    }else if (scroH > solution3Top - viewH / 3 && scroH < solution3Top - viewH / 3 + 20) {
          if (delta > 0) {
              // console.log('下滚2')
              $('html ,body').animate({
                  scrollTop: $('.section3').offset().top + 1
              }, 300);
          }
          if (delta < 0) {
              return false;
          }
      } else if (scroH > solution4Top - viewH / 3 && scroH < solution4Top - viewH / 3 + 20) {
          if (delta > 0) {
              // console.log('下滚3')
              $('html ,body').animate({
                  scrollTop: $('.section4').offset().top - $('.header').height()
              }, 300);
          }
          if (delta < 0) {
              return false;
          }
      }
  }
  beforeScroH = scroH;

});

$(document).ready(function(){
  setXscrollHeight()
})
$(window).resize(function(){
  setXscrollHeight()
})
function setXscrollHeight(){
  if($(window).width() > 1024){
    $('.section3').height($('.section3 .cont ul').outerWidth() - $(window).width() + $(window).height());
  }
}